import React, { Component, Fragment } from 'react';

class Autocomplete extends Component {

	constructor(props) {
    super(props);

    this.state = {
			suggestions: this.props.suggestions,
			currentSuggestions: [],
			entry: ""
    };

	}

	// Check the suggestions that should be displayed in the suggestions list
	checkSuggestions ( e ) {

		let suggestions = this.props.suggestions;
		let entry = e.target.value;
		var current = [];

		for ( var i in suggestions ) {
			if ( suggestions[i].name.toLowerCase().indexOf(entry.toLowerCase()) > -1 ) {
				current.push(suggestions[i]);
			}
		}

		this.setState({ currentSuggestions: current, entry: e.target.value });

	}

	// Select a given suggestion
	selectSuggestion ( e ) {

		e.preventDefault();

		// Figure out what was selected
		let selectedId = e.target.getAttribute("data-id");
		let selectedName = e.target.getAttribute("data-name");

		// Return the result to the handler
		this.props.selection(selectedId, selectedName);
		this.setState({ entry: "", currentSuggestions: [] });

	}

	// Render the view
  render() {

		let { currentSuggestions, entry } = this.state;

    return (
      <div className={ typeof this.props.dark == 'undefined' ? 'autocomplete' : 'autocomplete dark' }>

				<label htmlFor={ "autocomplete-" + this.props.id }>{ this.props.label }</label>

				{ typeof this.props.chosen != "undefined" && this.props.chosen ? (
					<div className="selected">
						{ this.props.chosen.map ( (entry, i) =>
                            <Fragment key={ "chosen-" + this.props.id + "-" + i }>
							<span >
								<a href="#remove" onClick={ (e) => this.props.removal(e) } data-id={ entry.id }>
									{ entry.name }
								</a>
							</span>
                            {this.props.chosen.length > 1 && i < this.props.chosen.length -1 && 
                                <span style={{opacity:0}}>
                                    ;
                                </span>
                            }
                            </Fragment>
						)}
					</div>
				) : "" }

				<input 
					type="text" 
					id={ "autocomplete-" + this.props.id } 
					value={ entry } 
					onChange={ (e) => this.checkSuggestions(e) } 
					onBlur={typeof this.props.onBlurCallbackHandler !== 'undefined' ? (e) => this.props.onBlurCallbackHandler(e) : undefined}
				/>

				<ul>
					{ currentSuggestions.map ( (sug, i) =>
						<li key={ "sug-" + i + "-" + sug.id } data-id={ sug.id }>
							<a style={(typeof sug.style !== 'undefined') ? sug.style : {}}  href="#select" data-id={ sug.id } data-name={ sug.name } onClick={ (e) => this.selectSuggestion(e) }>{ sug.name }</a>
						</li>
					)}
				</ul>

      </div>
    );
  }
}

export default Autocomplete;
