import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom'

import Header from './../common/header.js';
import Intro from './intro.js';
import Login from './login.js';
import Thanks from './thanks.js';
import ForgottenPassword from './forgot-pw.js';
import ResetPassword from './reset-pw.js';
import TabBlock from './tabs.js';
import ErrorPage from './error.js';
import DownloadLink from './download-link.js';
import Footer from './../common/footer.js';
import bg from './../../assets/img/bg.jpg';
import ErrorLinkPage from './error-link.js';
import UploadLink from './upload-link.js';

class PublicTemplate extends Component {

  render() {

    var styles = {
      main: {
        background: 'url(' + bg + ') no-repeat',
        backgroundSize: 'cover',
        width: '100%',
        height: '100%',
        position: 'fixed',
        top: 0,
        left: 0,
        overflow: 'auto'
      }
    };

    return (
      <div style={ styles.main }>
        <Header />

        <Switch>
          <Route exact path='/' render={(props) => <Intro cookies={this.props.cookies} {...props} />} />
          <Route exact path='/error' component={ ErrorPage }/>
          <Route exact path='/download/fail' component={ ErrorLinkPage }/>
          <Route exact path='/admin' component={ Login }/>
          <Route exact path='/thanks' component={ Thanks }/>
          <Route exact path='/forgotten-password' component={ ForgottenPassword }/>
            <Route path='/download/:slug/:token/:mash/:isBatch' component={ DownloadLink }/>
            <Route path='/download/:slug/:token/:mash' component={ DownloadLink }/>
            <Route path='/upload/:slug/:token/:mash' component={ UploadLink }/>
          <Route path='/password-reset/:slug/:mash/:isNew' component={ ResetPassword }/>
          <Route path='/password-reset/:slug/:mash/' component={ ResetPassword }/>
          <Route path='/password-reset/:slug' component={ ResetPassword }/>
          <Route path='/password-reset' component={ ResetPassword }/>
          <Route path='/questionnaire' render={(props) => <TabBlock cookies={this.props.cookies} {...props} />} />
          <Route path='/welcome/:token/:mash/:tokenKey' render={(props) => <Intro cookies={this.props.cookies} {...props} />} />
          <Route exact path='/welcome/' render={(props) => <Intro cookies={this.props.cookies} {...props} />} />
        </Switch>

        <Footer />
      </div>
    );
  }
}

export default PublicTemplate;
