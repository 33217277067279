import React, { Component } from 'react';
import Fetching from './../../functions/fetching.js';

class PublicUpload extends Component {

	// Constructor to set up data and set init state
	constructor(props) {
		super(props);

		this.state = {
			fileId: "",
			formAction: "",
			filename: "",
			acl: "",
			cred: "",
			algo: "",
			lnkExpiry: "",
			policy: "",
			sig: "",
			formDisabled: true,
            isUploading:false,
            uploadPercentage: 0
		};

		this.getFormDetails = this.getFormDetails.bind(this);
		this.confirmUpload = this.confirmUpload.bind(this);
	}


	getFormDetails (e) {

		// Disable the input submit while processing the update
		this.setState({ formDisabled: true , isUploading:true,uploadPercentage:0});

		// Get the file name
		var fullPath = e.target.value;
		var re = /(?:\.([^.]+))?$/;

		if (fullPath) {
			var startIndex = (fullPath.indexOf('\\') >= 0 ? fullPath.lastIndexOf('\\') : fullPath.lastIndexOf('/'));
			var filename = fullPath.substring(startIndex);
			if (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) {
				filename = filename.substring(1);
			}

			var fileType = re.exec(filename)[1];
		}

		var isVideo = 0;
		var videofileTypes = ['MP4', 'MOV', 'WMV', 'AVI','FLV'];

		videofileTypes.forEach((type) => {
			if(fileType.toLowerCase() === type.toLowerCase()){
				isVideo = 1;
			}
		});

		let { fileId } = this.state;

		var fileData = { filename: filename, fid: fileId, fileContent: e.target.files[0],isVideo:isVideo,filesize:e.target.files[0].size };
		var thus = this;

        var slug = this.props.slug;
        var token = this.props.token;

		fetch(process.env.REACT_APP_API_BASE + '/files/public_post_details/uploads/' + slug +'/' + token, {
			method: 'post',
			headers: {
				"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
			},
			body: 'data=' + JSON.stringify(fileData) 
		})
		.then(Fetching.statusCheck)
		.then(Fetching.jsonExtract)
		.then(function (data) {
			if ( typeof data.fid != "undefined" ) {
				console.log('Request succeeded with JSON response', data);
				thus.setState({
					fileId: data.fid,
					formAction: data.attributes.action,
					filename: data.subName, // TODO - remove this!!!
					acl: data.inputs.acl,
					cred: data.inputs['X-Amz-Credential'],
					algo: data.inputs['X-Amz-Algorithm'],
					lnkExpiry: data.inputs['X-Amz-Date'],
					policy: data.inputs['Policy'],
					sig: data.inputs['X-Amz-Signature'],
					formDisabled: false
				});
				
				thus.doFetchSubmit(fileData);
			} else {
				alert("There was an error updating your agenda. Please check the content and try again.");
			}
		})
		.catch(function (error) {
            thus.setState({isUploading:false})
			console.log('Request failed', error);
		});
	}

	doFetchSubmit ( fileData ) {

		// Assemble the core data for form submission
        let { formAction, filename, acl, cred, algo, lnkExpiry, policy, sig } = this.state;
        var submitData = new FormData();
        submitData.append("key", filename);
        submitData.append("acl", acl);
        submitData.append("X-Amz-Credential", cred);
        submitData.append("X-Amz-Algorithm", algo);
        submitData.append("X-Amz-Date", lnkExpiry);
        submitData.append("Policy", policy);
        submitData.append("X-Amz-Signature", sig);
        submitData.append("file", fileData.fileContent);

        var thus = this;
        var xhr = new XMLHttpRequest();

        xhr.open('POST', formAction, true);

        xhr.upload.onprogress = function(event) {
            if (event.lengthComputable) {
                var percentComplete = (event.loaded / event.total) * 100;
                console.log(`Upload progress: ${percentComplete.toFixed(2)}%`);
                thus.setState({uploadPercentage:percentComplete})
            }
        };

        xhr.onload = function() {
            if (xhr.status >= 200 && xhr.status < 300) {
                console.log('Upload succeeded. Handle confirmation.');
                thus.confirmUpload();
            } else {
                console.log('Request failed', xhr.statusText);
            }
        };

        xhr.onerror = function() {
            console.log('Request failed', xhr.statusText);
            thus.setState({isUploading:false})
        };

        xhr.send(submitData)
	}

	confirmUpload () {

		let { fileId } = this.state;

        var slug = this.props.slug;
        var token = this.props.token;

    // If we have the tokens then get the tabs for this
    var fetchUrl = process.env.REACT_APP_API_BASE + '/files/public_confirm_upload/' + fileId + '/uploads/'+ slug +'/'+ token;
    var thus = this;
    fetch(fetchUrl, { credentials:"include" })
      .then(response => response.json())
      .then(
        function (data) {

          if (typeof data.rslt == "undefined" || data.rslt !== 'success') {
            alert("Sorry but there was an error uploading your file. Please try again.");
          } else {
						thus.setState({ fileId: "" });
						if (typeof thus.props.callbackFunc != 'undefined') {
							if(typeof thus.props.isCommunication !== 'undefined'  && thus.props.isCommunication === true){
								thus.props.callbackFunc( {"id":fileId,"file_name":thus.state.filename});
							} else {
								thus.props.callbackFunc();
							}
							
						}
					}


        }
      );

		// Clear the file input
		document.getElementById("fileInput").value = "";
        this.setState({isUploading:false})
  }

  render() {

    let { formAction, isUploading,uploadPercentage } = this.state;

    let uploadText = "Uploading..."

    if(isUploading && uploadPercentage === 100){
        uploadText = "Uploaded"
    }

    return (
        <form action={ formAction } method="post" encType="multipart/form-data" id="uploadForm">
          <label htmlFor='fileInput' className="uploader">
              {isUploading &&
              <span className="uploaderProgressBar" style={{opacity:uploadPercentage !== 100 ?  0.5: 1,width: uploadPercentage+"%"}}></span>
              }
          </label>
          {isUploading && <span className="uploaderProgressText" style={{color: uploadPercentage === 100 ?'#FFF':undefined}}>{uploadText}</span> }
          <input type="file" name="file" id="fileInput" onChange={ (e) => this.getFormDetails(e) } className="uploaderFormInput" style={{opacity: isUploading? 0 : 1}} />
        </form>
  );
  }
}


export default PublicUpload;
