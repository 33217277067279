import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
import Heading from './heading.js';

import Fetching from '../../functions/fetching.js';
import Colours from '../../assets/colours.js';
import Download from './../../assets/img/download.svg';
import PlayIcon from './../../assets/img/play-icon.svg';
import PublicUpload from './public-upload.js';

class UploadLink extends Component {

  constructor(props) {
    super(props);

    this.state = {
      error: "",
      success: "",
        rdr: false,
        files: [],
        clientName: "",
        isLoading: true,
        activeFile:{},
        coverContentOpen:false,
        vimeoEmbedId: false,
        minTimeWatchedSecondsTriggered: false,
        minTimeWatchedPercentageTriggered: false
	};
	
    this.deleteFile = this.deleteFile.bind(this);
	this.hideLnk = this.hideLnk.bind(this);
    this.submitFile = this.submitFile.bind(this)
  }

  // Fetch the list of users (paginated?!)
  componentDidMount () {

    // Check for pagination
		this.getDetails();

  }

  trackVideoEvent(fileId, eventType){
        var slug = this.props.match.params.slug;
		var token = this.props.match.params.token;

        var thus = this;
		fetch(process.env.REACT_APP_API_BASE + '/files/track_event/' + slug + "/" + token + "/"+ fileId + "/"+ eventType, { credentials:"include" })
		.then(Fetching.statusCheck)
		.then(Fetching.jsonExtract)
		.then(function (data) {
		console.log('Request succeeded with JSON response', data);

		if (typeof data.error != 'undefined') {
			// Set the updated state
			thus.setState({ rdr: true });
		} else {
			console.log("Event tracked - " + eventType)
		}

		})
		.catch(function (error) {
		    console.log('Request failed', error);
		});

  }


	getDetails () {

		// Get the URL data
		var slug = this.props.match.params.slug;
		var token = this.props.match.params.token;
		var mash = this.props.match.params.mash;

		// Retrieve the page data
		var thus = this;
		fetch(process.env.REACT_APP_API_BASE + '/customer_sends/details/' + slug + "/" + token + "/" + mash, { credentials:"include" })
		.then(Fetching.statusCheck)
		.then(Fetching.jsonExtract)
		.then(function (data) {
		console.log('Request succeeded with JSON response', data);

		if (typeof data.error != 'undefined') {
			// Set the updated state
			thus.setState({ rdr: true });
		} else {
			thus.setState({ clientName: data.clientName, files: data.files, isLoading: false });
		}

		})
		.catch(function (error) {
		    console.log('Request failed', error);
		});

	}

    deleteFile (e, fid) {

		// Get the URL data
		var slug = this.props.match.params.slug;
		var token = this.props.match.params.token;

        var doDelete = window.confirm('Do you wish to delete this file?');

        if(!doDelete){
            return false
        }

		// Retrieve the page data
		var thus = this;
		fetch(process.env.REACT_APP_API_BASE + '/files/public_delete/' +fid + '/' + slug + "/" + token + "/" , { credentials:"include" })
		.then(Fetching.statusCheck)
		.then(Fetching.jsonExtract)
		.then(function (data) {
            console.log('Request succeeded with JSON response', data);

            if (typeof data.error != 'undefined') {
                // Set the updated state
                thus.setState({ rdr: true });
            } else {
                thus.getDetails()
            }

		})
		.catch(function (error) {
		    console.log('Request failed', error);
		});

	}

    submitFile () {

        this.setState({success:""})

		// Get the URL data
		var slug = this.props.match.params.slug;
		var token = this.props.match.params.token;

		// Retrieve the page data
		var thus = this;
		fetch(process.env.REACT_APP_API_BASE + '/files/public_submit/' + slug + "/" + token + "/" , { credentials:"include" })
		.then(Fetching.statusCheck)
		.then(Fetching.jsonExtract)
		.then(function (data) {
            console.log('Request succeeded with JSON response', data);

            if (typeof data.error != 'undefined') {
                // Set the updated state
                thus.setState({ rdr: true });
            } else {
                thus.setState({success:"Thank you for uploading your files, we have emailed the Ashworth team to let them know. You may now close this window."})
            }

		})
		.catch(function (error) {
		    console.log('Request failed', error);
		});

	}

	hideLnk(e) {
		this.setState({coverContentOpen:false})
	}

  render() {

	  const { error, files, clientName, isLoading, rdr ,success } = this.state;
		var slug = this.props.match.params.slug;
		var token = this.props.match.params.token;
		var mash = this.props.match.params.mash;

		var clName = clientName;
		if ( typeof this.props.match.params.isBatch != 'undefined' && this.props.match.params.isBatch === "y" ) {
			clName = "";
		}

    if (rdr) {
      return <Redirect to={"/download/fail"} />
    }

		var isBatch = "";
		if (typeof this.props.match.params.isBatch != "undefined" && this.props.match.params.isBatch === "y") {
			isBatch = "y";
		}

		
		var content = (
            <div style={ styles.container }>
                <p className="intro">Thank you for coming to upload your files.</p>
                <p>Please upload your files using the form below. Bear in mind
                that this link will expire after 30 days.</p>
                <ul style={ styles.ul }>
                    { files.map( file => {

                        let viewUrl = null; // eslint-disable-line
                        if(file.is_video){
                            // eslint-disable-next-line
                            viewUrl = <a onClick={(e) => this.openVideoPlayer(e,file.id)}href='#' target="_blank" rel="noopener noreferrer" style={ styles.playBtn }>View Video</a>
                        }
                        return (
                            <li key={ file.id } style={ styles.li }>
                                { file.name }
                                <a href={process.env.REACT_APP_API_BASE + '/files/download/' + file.id + '/' + slug + '/' + token + '/' + mash + "/" + isBatch} target="_blank" rel="noopener noreferrer" style={styles.downloadBtnGrey} download={file.name}>Download</a>
                                <a href='#download' rel="noopener noreferrer" style={styles.deleteBtnGrey} onClick={(e) => this.deleteFile(e,file.id)}>Delete</a>
                            </li>
                        )
                    })}
                </ul>
                <PublicUpload custRef={ this.props.custRef } slug={this.props.match.params.slug}  token={this.props.match.params.token} callbackFunc={ () => this.getDetails() } />
                <button style={styles.submitBtn} onClick={this.submitFile}>Submit Files</button>
            </div>
            );

		if (isLoading) {
    	content = <div className='width-1 centred'>
                    <div className="loader">Loading...</div>
                  </div>;
		}

    var errorContent = "";
    if (error !== "") {
      errorContent = <div className='error mbtm' style={ styles.full }>
                      { error }
                    </div>
    }

    errorContent = "";
    if (success !== "") {
      errorContent = <div className='success mbtm' style={ styles.full }>
                      { success }
                    </div>
    }

    return (
		
      <div className='width-6'>
        <Heading mainTitle="Upload your files" subTitle={ clName } />
		
        { errorContent }
        { content }

      </div>
    );
  }
}

var styles = {
	container: {
		background: Colours.bgWhite75,
		marginTop: 35,
		marginBottom: 125,
		padding: 25
	},
	ul: {
		marginLeft: 45,
		marginTop: 25
	},
	li: {
		margin: "12px 0px"
	},
	downloadBtn: {
		display: 'inline-block',
		background: Colours.grn + " url('" + Download + "') left 12px center / 14px auto no-repeat",
		color: Colours.txtWhite,
		fontSize: 14,
		padding: "3px 12px 3px 35px",
		marginLeft: 25,
		borderRadius: 20,
		textDecoration: 'none'
	},
	downloadBtnGrey: {
		display: 'inline-block',
		background: Colours.txtGrey + " url('" + Download + "') left 12px center / 14px auto no-repeat",
		color: Colours.txtWhite,
		fontSize: 14,
		padding: "3px 12px 3px 35px",
		marginLeft: 25,
		borderRadius: 20,
		textDecoration: 'none'
	},
    deleteBtnGrey: {
		display: 'inline-block',
		background: Colours.txtGrey,
		color: Colours.txtWhite,
		fontSize: 14,
		padding: "3px 12px 3px 12px",
		marginLeft: 25,
		borderRadius: 20,
		textDecoration: 'none'
	},
	playBtn: {
		display: 'inline-block',
		background: Colours.grn + " url('" + PlayIcon + "') left 14px center / 12px auto no-repeat",
		color: Colours.txtWhite,
		fontSize: 14,
		padding: "3px 12px 3px 32px",
		marginLeft: 8,
		borderRadius: 20,
		textDecoration: 'none'
	},
    submitBtn:{
        display: 'inline-block',
		background: Colours.grn,
		color: Colours.txtWhite,
		fontSize: 14,
		padding: "3px 12px 3px 12px",
		borderRadius: 20,
		textDecoration: 'none',
        border:'none',
        cursor:'pointer'
    }
};

export default UploadLink;
