import React, { Component } from 'react';

import { Link, Redirect } from 'react-router-dom';
import Heading from './../heading.js';
import FlashMsg from './../../common/flash-msg.js';

import Fetching from './../../../functions/fetching.js';

import Autocomplete from './../common/autocomplete.js';
import { connect } from 'react-redux';



class EmailGroupEdit extends Component {

  constructor(props) {
    super(props);

    this.state = {
      email_group: {
		  customers:[],
		  email_group:{
			  id:-1,
			  name:'',
			  slug:'*C*'
		  }
	  },
      isLoading:true,
	  recipientSuggestions:[],
	  recipients:[],
	  rdr:''
    };

	this.selectRecipient = this.selectRecipient.bind(this);
	this.removeRecipient = this.removeRecipient.bind(this);
	this.handleEmailGroupInput = this.handleEmailGroupInput.bind(this);
	this.saveGroupEmail = this.saveGroupEmail.bind(this);
  }

  saveGroupEmail ( e ) {
	  e.preventDefault();

	  // Set up the data for the submission
	  let { email_group,  recipients} = this.state;

	  console.log(recipients);
	  let saveData = {
		  recipients: this.formatForFetch(recipients),
		  email_group:email_group
	  }

	  console.log(saveData);

	  let slug = email_group.email_group.slug
	  // Run the save enquiry
	  let thus = this;
	  fetch(process.env.REACT_APP_API_BASE + '/admin/email_groups/save/'+slug, {
		  method: 'post',
		  headers: {
			  "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
		  },
		  credentials:"include",
		  body: 'data=' + JSON.stringify(saveData) + '&' + this.props.tokenKey + '=' + this.props.token
	  })
	  .then(Fetching.statusCheck)
	  .then(Fetching.jsonExtract)
	  .then(function (data) {
		  if (data.rslt === 'success') {
			  thus.setState({ success: "Your email has been sent successfully.", rdr: "/admin/email_group" });
		  } else {
			  thus.setState({ success: "", error: "There was an error sending your message. Please check the content and try again." });
		  }
		  thus.getTokens();
	  })
	  .catch(function (error) {
		  thus.getTokens();
		  thus.setState({ success: "", error: "There was an error sending your message. Please check the content and try again." });
	  });
  }

  // Get a list of potential recipients + use it to populate the recipients autocomplete
  getCustomers () {
	  var fetchUrl = process.env.REACT_APP_API_BASE + '/admin/customers/emails_list/';
	  var thus = this;
	  fetch(fetchUrl, { credentials:"include" })
		  .then(response => response.json())
		  .then(
			  function (data) {

				  if (typeof data.rslt != "undefined" && data.rslt === 'success') {
					  thus.setState({
						  recipientSuggestions: data.data
					  });
				  }

			  }
		  );
  }

  formatForFetch ( usrList ) {

	  var newLst = [];
	  for ( var i in usrList ) {
		  newLst.push({
			  id: usrList[i].id,
			  name: encodeURIComponent(usrList[i].name)
		  });
	  }

	  return newLst;

  }

  initialiseComponent (props) {

	let emailGroupSlug = props.match.params.slug
    var thus = this;
    fetch(process.env.REACT_APP_API_BASE + '/admin/email-groups/get/'+emailGroupSlug, { credentials:"include" })
      .then(Fetching.statusCheck)
      .then(Fetching.jsonExtract)
      .then(
        function (data) {

          // Check whether we have a failure
          if (typeof data.error !== 'undefined' && data.error === 'auth') {

            // Redirect to the error page
            thus.setState({ error: true });
            return false;

          } else {

			// Set up the data for state
			let email_group = { ...data.data };
            let isLoading = false;
            // Process the data for display of the users
            thus.setState({isLoading,email_group},() => thus.populateAutoCompleteFromPageLoad());
            return data;
          }
        }
      );
  }


  getTokens () {
	fetch(process.env.REACT_APP_API_BASE + '/users/tokens', { method: 'get', credentials:"include" })
	  .then(Fetching.statusCheck)
	  .then(Fetching.jsonExtract)
	  .then(data => this.props.dispatch({ type: 'SET_TOKENS', token: data.token, tokenKey: data.tokenKey, sessionId: data.session }))
	  .catch(function (error) {
		console.log('Request failed', error);
	  });
  }


  componentWillReceiveProps(nextProps){
 	//call your api and update state with new props
	if(!this.props.isCreate){
	 	this.initialiseComponent(nextProps);
 	}
  }

  componentDidMount () {
	  this.getCustomers ();
	  if(!this.props.isCreate){
		  this.initialiseComponent(this.props);
	  }

  }

  populateAutoCompleteFromPageLoad(){
	let email_group_recipients = [...this.state.email_group.customers];
	let newRecipients = [];

	email_group_recipients.forEach((recipient) => {
		newRecipients.push({ id: recipient.id, name: recipient.name });
	})

	this.setState({ recipients: newRecipients });
  }

  selectRecipient ( id, name ) {

	  // Get the existing recipients
	  let { recipients } = this.state;

	  var newRecipients = [];
	  var isIncluded = false;

	  // Check whether the existing is already in the recipients array
	  for ( var i in recipients ) {
		  newRecipients.push(recipients[i]);
		  if ( recipients[i].id === id ) {
			  isIncluded = true;
		  }
	  }

	  // If the recipient is not in the recipients array then add it
	  if (!isIncluded) {
		  newRecipients.push({ id: id, name: name });
	  }

	  this.setState({ recipients: newRecipients });
  }

  removeRecipient ( e ) {

	  e.preventDefault();

	  // Get the recipient ID
	  let custId = e.target.getAttribute('data-id');

	  // Get the recipients list
	  let { recipients } = this.state;
	  var newRecipients = [];

	  // Check whether the existing is already in the recipients array
	  for ( var i in recipients ) {
		  if ( custId !== recipients[i].id ) {
			  newRecipients.push(recipients[i]);
		  }
	  }

	  this.setState({ recipients: newRecipients });
  }

  handleEmailGroupInput(e){
	  let newEmailGroupName = e.target.value;
	  let email_group = {...this.state.email_group};
	  email_group.email_group.name = newEmailGroupName;
	  this.setState({email_group});
  }

  // Render the view
  render() {

    const { email_group,recipientSuggestions, recipients,rdr } = this.state;

  	if ( rdr ) {
		return (
			<Redirect to={ rdr } />
		);
	}

	let heading = (this.props.isCreate) ? <Heading mainTitle="Email Group Create" /> : <Heading mainTitle="Email Group Edit"  />;
	  console.log(email_group);
	let grpName = typeof email_group.gname !== 'undefined' ? email_group.gname : "";

	return (


      <div className='width-8 centred mbtm-lrg'>

        { heading }

        <FlashMsg />

		<div className='question'  style={{marginLeft:0}}>
          <label>Email group name</label>
          <input style={{marginLeft:'10px'}}  name={ 'email_group_name'} defaultValue={ grpName } type="text"  onKeyUp={ (e) => this.handleEmailGroupInput(e) } />
        </div>

		<div style={{clear:'both'}}></div>
		  <Autocomplete
			  id="1"
			  label="Group includes the following customer(s)"
			  suggestions={ recipientSuggestions }
			  selection={ this.selectRecipient }
			  chosen={ recipients }
			  removal={ this.removeRecipient }
		  />

		  <button onClick={this.saveGroupEmail} style={style.save_btn}>Save</button>
		  <Link style={{...style.save_btn,marginRight:8}} to={'/admin/email_group'}>Back</Link>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    token: state.token,
    tokenKey: state.tokenKey,
  };
}

const style = {
	save_btn:{
		background: "rgb(78, 144, 67)",
		color: "rgb(255, 255, 255)",
		borderRadius: "20px",
 		textAlign: "center",
		textDecoration: "none",
		fontSize: '14px',
		display: 'block',
		padding: '5px 12px',
		float:'right',
		marginTop:'10px',
		border:0,
		cursor:'pointer'
	}
}


export default connect(mapStateToProps)(EmailGroupEdit);
