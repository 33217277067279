import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Fetching from './../../../functions/fetching.js';

import Heading from './../heading.js';
// import FlashMsg from './../../common/flash-msg.js';

import EmailFilter from './filter.js';
import EmailList from './list.js';
import DisplayComposeOptionDropdown from './display-compose-option-dropdown.js';

class EmailsIndex extends Component {

	constructor(props) {
    super(props);

    this.state = {
      users: [],
			mailboxes: [ "inbox", "sent", "draft",'meeting-notes','call' ,'email'],
			search: "",
			emails: [],
			emailsLoading: false,
			customers: [],
			customerSuggestions: [],
			totalPages: 1,
			currentPage: (typeof this.props.match.params.pageNo != 'undefined') ? this.props.match.params.pageNo : 1,
			prevPage: 1,
			nextPage: 1,
			loading: true,
			canCompose: true,
			showNestedEmailCheckbox: true,
			success:"",
			error:"",
			includeArchived:false
    };

		this.updateFilter = this.updateFilter.bind(this);
		this.getEmails = this.getEmails.bind(this);
		this.updateCustomers = this.updateCustomers.bind(this);
		this.removeCustomers = this.removeCustomers.bind(this);
		this.loadFlashMessage = this.loadFlashMessage.bind(this);
		this.toggleArchiveEmail= this.toggleArchiveEmail.bind(this);

        this.controller = new AbortController()
	}

	// On component mount load up the users, which triggers email loading
	componentWillMount () {
		
		this.getTokens();
		this.getCustomers();
		this.initialiseCustomers();
		
	}

	componentDidMount(){
		this.loadFlashMessage();
	}

	loadFlashMessage(){

		if(typeof this.props.location.state !== 'undefined'){
			if(typeof this.props.location.state.success !== 'undefined'){
				let successMsg = this.props.location.state.success;
				this.setState({success:successMsg});
				window.history.replaceState(null, '');

			}
		}
	}

	componentWillReceiveProps ( newProps ) {
		if (typeof newProps.match.params.pageNo != 'undefined' && newProps.match.params.pageNo !== this.state.currentPage ) {
			this.setState({ currentPage: newProps.match.params.pageNo });
			this.getEmails(newProps);
		}
	}

	toggleArchiveEmail(index){
		let emails = [...this.state.emails];

		let newEmail = emails[index];

		newEmail.is_archived = !newEmail.is_archived;

		emails[index] = newEmail;

		let archiveState = (newEmail.is_archived) ? 1 : 0


		let thus = this;
			fetch(process.env.REACT_APP_API_BASE + '/admin/user_emails/toggle_archive/' + newEmail.id+'/'+archiveState, { 
				credentials:"include" ,
				method: 'post',
				headers: {
					"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
				},
			body: this.props.tokenKey + '=' + this.props.token
			})
				.then(Fetching.statusCheck)
				.then(Fetching.jsonExtract)
				.then(
					function (data) {
						// Check whether we have a failure
						if (typeof data.rslt != 'undefined' && data.rslt === 'success') {

							// Redirect to the error page
							thus.setState({emails:emails});

						} 

						thus.getTokens();
					}
				).catch(function (error) {
					thus.getTokens();
		    });


		
	}

	// If we have a customer reference set then set up the initial filter
	initialiseCustomers () {
		if (typeof this.props.match.params.custRef != 'undefined') {

			let thus = this;
			fetch(process.env.REACT_APP_API_BASE + '/admin/customers/name_details/' + this.props.match.params.custRef, { credentials:"include" })
				.then(Fetching.statusCheck)
				.then(Fetching.jsonExtract)
				.then(
					function (data) {
						// Check whether we have a failure
						if (typeof data.rslt != 'undefined' && data.rslt === 'success') {

							// Redirect to the error page
							thus.setState({ customers: data.data });

							// Trigger the search for the users
							setTimeout(function(){
								thus.loadUsers();
							}, 250);

						} else {
							// Trigger the search for the users
							setTimeout(function(){
								thus.loadUsers();
							}, 250);
						}
					}
				).catch(function (error) {
					setTimeout(function(){
						thus.loadUsers();
					}, 250);
		    });

		} else {

			// Trigger the search for the users
			let thus = this;
			setTimeout(function(){
				thus.loadUsers();
			}, 250);

		}

	}

	// Load the users for the filters
	loadUsers () {

		let thus = this;
		fetch(process.env.REACT_APP_API_BASE + '/admin/users/list_sso_users', { credentials:"include" })
			.then(Fetching.statusCheck)
			.then(Fetching.jsonExtract)
			.then(
				function (data) {
					// Check whether we have a failure
					if (typeof data.rslt != 'undefined' && data.rslt === 'success') {

						// Redirect to the error page
						thus.setState({ users: data.data });
						thus.getEmails(thus.props);

					}
				}
			);

	}

	// Get a list of potential recipients + use it to populate the recipients autocomplete
	getCustomers () {
		var fetchUrl = process.env.REACT_APP_API_BASE + '/admin/customers/emails_list/';
		var thus = this;
		fetch(fetchUrl, { credentials:"include" })
			.then(response => response.json())
			.then(
				function (data) {

					if (typeof data.rslt != "undefined" && data.rslt === 'success') {
						thus.setState({
							customerSuggestions: data.data
						});
					}

				}
			);
	}

	// Updates to the filter that triggers the update of the email list
	updateFilter ( e ) {

		console.log(e.target.type);
		console.log(e.target.value);

		if (e.target.type === "checkbox") {

			// If it is a checkbox then handle it
			var inputVal = e.target.value;

			if ( inputVal.indexOf("mailbox-") > -1 ) {

				let { mailboxes } = this.state;
				var newMailboxes = [];
				inputVal = inputVal.replace("mailbox-", "");

				for ( var i in mailboxes ) {
					if ( mailboxes[i] !== inputVal ) {
						newMailboxes.push(mailboxes[i]);
					}
				}

				if (e.target.checked) {
					newMailboxes.push(inputVal);
				}

				let showNestedEmailCheckbox = true;

				if(newMailboxes.indexOf('email') === -1){
					showNestedEmailCheckbox = false;
				}

				this.setState({ mailboxes: newMailboxes,showNestedEmailCheckbox });
				this.getEmails(this.props, 'mailboxes', newMailboxes);

			} else if ( inputVal === 'include-archive' ) {
				this.setState(prevState => ({
					includeArchived: !prevState.includeArchived
				  }),() => {
					this.getEmails(this.props, 'users', users);
				  })
			} else {

				var { users } = this.state;
				inputVal = inputVal.replace("user-", "");

				for ( var j in users ) {
					if (users[j].id === inputVal) {
						if ( e.target.checked ) {
							users[j].selected = true;
						} else {
							users[j].selected = false;
						}
					}
				}

				this.setState({ users: users });
				this.getEmails(this.props, 'users', users);

			}

		} else {
			// Otherwise handle the search text input
			e.preventDefault();
			this.setState({ search: e.target.value });

			// Trigger from here to handle state updates
			this.getEmails(this.props, 'search', e.target.value);
		}

	}

	getEmails ( useProps, changed, values) {

		let { users, mailboxes, search, customers,includeArchived } = this.state;

		this.setState({ emailsLoading: true });

		// Handle the customers to encode illegal characters
		var newCustomers = [];
		if (changed === 'customers') {
			for (var i in values) {
				newCustomers.push({
					id: values[i].id,
					name: encodeURIComponent(values[i].name)
				});
			}
		} else {
			for (var j in customers) {
				newCustomers.push({
					id: customers[j].id,
					name: encodeURIComponent(customers[j].name)
				});
			}
		}

		// Set up the data for the filter
		let filterData = {
			users: (changed === 'users') ? values : users,
			customers: newCustomers,
			mailboxes: (changed === 'mailboxes') ? values : mailboxes,
			search: (changed === 'search') ? values : search,
			includeArchived: (includeArchived) ? 1 : 0
		};

        const signal = this.controller.signal;

		let pageNo = (typeof useProps.match.params.pageNo != 'undefined') ? useProps.match.params.pageNo : 1 ;

		let thus = this;
		fetch(process.env.REACT_APP_API_BASE + '/admin/sso/get_emails/' + pageNo, {
            signal,
			method: 'post',
			headers: {
				"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
			},
			credentials:"include",
			body: 'data=' + JSON.stringify(filterData) + '&' + this.props.tokenKey + '=' + this.props.token
			})
			.then(Fetching.statusCheck)
			.then(Fetching.jsonExtract)
			.then(function (data) {

				if (typeof data.rslt != "undefined" && data.rslt === 'success') {

					thus.getTokens();
					thus.setState({
						emails: data.data,
						totalPages: data.paginate.totalPages,
						currentPage: pageNo,
						prevPage: data.paginate.prevPage,
						nextPage: data.paginate.nextPage,
						loading: false,
						emailsLoading: false
					});



				} else if( data.rslt === 'secure-fail') {
					thus.getTokens();
					thus.getEmails ( useProps, changed, values);			
					
				} else {	
					thus.getTokens();
					thus.setState({ loading: false, emailsLoading: false });						
				}

			
			})
			.catch(function (error) {
			thus.getTokens();
					thus.setState({ loading: false, emailsLoading: false });
			console.log('Request failed', error);
			});

	}

	// Select the customers that should be filtered
	updateCustomers ( id, name ) {

		// Get the current list of customers selected
		let { customers } = this.state;
		var selCust = [];

		// Loop through the list of customers and chec whether the entered customer has been sent
		var isSelected = false;
		for ( var i in customers ) {
			if ( customers[i].id === id ) {
				isSelected = true;
			}
			selCust.push(customers[i]);
		}

		// If the selected customer is not already in the
		if (!isSelected) {
			selCust.push({ id: id, name: name });
		}

		// Set the customers state
		this.setState({ customers: selCust });
		this.getEmails(this.props, 'customers', selCust);

	}

	// Remove a user from the selected customers
	removeCustomers ( e ) {

		e.preventDefault();

		// Get the recipient ID
		let custId = e.target.getAttribute('data-id');

		// Get the recipients list
		let { customers } = this.state;
		var newCustomers = [];

		// Check whether the existing is already in the recipients array
		for ( var i in customers ) {
			if ( custId !== customers[i].id ) {
				newCustomers.push(customers[i]);
			}
		}

		this.setState({ customers: newCustomers });
		this.getEmails(this.props, 'customers', newCustomers);

	}

	getTokens () {
    fetch(process.env.REACT_APP_API_BASE + '/users/tokens', { method: 'get', credentials:"include" })
      .then(Fetching.statusCheck)
      .then(Fetching.jsonExtract)
      .then(data => this.props.dispatch({ type: 'SET_TOKENS', token: data.token, tokenKey: data.tokenKey, sessionId: data.session }))
      .catch(function (error) {
        console.log('Request failed', error);
      });
  }

  componentWillUnmount() {
    // Cancel the fetch request when the component unmounts
    this.controller.abort();
  }

  render() {

		let { users, mailboxes, search, emails, customers, customerSuggestions, totalPages, currentPage, prevPage, nextPage, loading, canCompose, emailsLoading,showNestedEmailCheckbox,success,error,includeArchived } = this.state;

		if ( loading ) {
			return (
				<div className='width-1 centred'>
					<div className="loader whiteBg">Loading...</div>
				</div>
			);
		}

		var composeBtn = "";
		if (canCompose) {
			composeBtn = <DisplayComposeOptionDropdown/>
		}

		let emailStyle = {marginRight:'5px'};

		let emailGroupBtn = <Link to="/admin/email_group" className="right btn grey" style={ {...styles.mtop,...emailStyle} }>Email Groups</Link>

		var flashContent = "";
		if ( success !== "" ) {
			flashContent = <div className='success mbtm'>{ success }</div>
		}
		if ( error !== "" ) {
			flashContent = <div className='error mbtm'>{ error }</div>
		}
    return (
      <div className='content'>

				<EmailFilter
					updateFilter={ this.updateFilter }
					users={ users }
					mailboxes={ mailboxes }
					search={ search }
					customers={ customers }
					customerSuggestions={ customerSuggestions }
					updateCustomers={ this.updateCustomers }
					removeCustomers={ this.removeCustomers }
					showNestedEmailCheckbox={showNestedEmailCheckbox}
					includeArchived={includeArchived}
				/>

				<div className="width-9">
					{ composeBtn }
					{emailGroupBtn}
					<Heading mainTitle="Manage Company Communications" subTitle="Filter and search through your team's client communications" />

					{flashContent}

					<EmailList
						emails={ emails }
						totalPages={ totalPages }
						currentPage={ currentPage }
						prevPage={ prevPage }
						nextPage={ nextPage }
						emailsLoading={ emailsLoading }
						toggleArchiveEmail={this.toggleArchiveEmail}
					/>
				</div>

      </div>
    );
  }
}

const styles = {
	mtop: {
		marginTop: 48
	}
}

function mapStateToProps(state) {
  return {
    token: state.token,
    tokenKey: state.tokenKey,
  };
}

export default connect(mapStateToProps)(EmailsIndex);
