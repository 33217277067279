import React, { Component } from 'react';

import Colours from './../../../assets/colours.js';

import Download from './../../../assets/img/download.svg';
import ProgressReviewIcon from './../../../assets/img/doc-icon.svg';

import Fetching from './../../../functions/fetching.js';
import { Chart } from "react-google-charts";

import { DISPLAY_BOTH } from './planning-components/constants/constants.js'
import { createDataEntries } from './planning-components/data-generator.js'
import { connect } from 'react-redux';

import CONFIGURATION from "./planning-components/series-configuration.js";
import Dropdown from './content/dropdown.js'

class ProgressReview extends Component {

  constructor(props) {
    super(props);

    this.state = {
      imagesLoading:false,
      imageURIs:[],
      imagesLoaded:[],
      calculatorSettings:[],
      savings:{},
      dropdownVisible:false,
      checkedCalculators:[],
      downloadPDF:false,
	    planDisplayDetails:{},
      showPercentageReturn:false,
      showInvestmentBreakdown:false,
      firstLoadCompleted:false
    };

    this.generateProgressReview = this.generateProgressReview.bind(this);
    this.toggleDropDown = this.toggleDropDown.bind(this);
    this.handleCheckToggle = this.handleCheckToggle.bind(this);
    this.handleGenerateProgressReviewClick = this.handleGenerateProgressReviewClick.bind(this);
    this.handleDropDownBlur = this.handleDropDownBlur.bind(this);
	this.getDefaultDisplaySettings = this.getDefaultDisplaySettings.bind(this);
	this.handleDownloadPDFSubMenuButtonClick = this.handleDownloadPDFSubMenuButtonClick.bind(this);
  this.toggleShowPercentageReturn = this.toggleShowPercentageReturn.bind(this)
  this.toggleShowInvestmentBreakdown = this.toggleShowInvestmentBreakdown.bind(this)
  this.loadCalculators = this.loadCalculators.bind(this)
  }

  componentWillReceiveProps(nextProps){
	  if(this.props.renderedFromTimeMachine === 'undefined' || !this.props.renderedFromTimeMachine){
		//   this.loadCalculators(nextProps)
	  }

  }

  componentDidMount(){

    setTimeout(() => {
    //   this.loadCalculators(this.props, true)
      if(typeof this.props.downloadAsPDF !== 'undefined'){
        this.setState({
          downloadAsPDF:this.props.downloadAsPDF
        })
      }
    },1000);


    

  }

  getDefaultDisplaySettings(){
	  return {
        startAgeCrop:35,
        endAgeCrop:100,
        holdingStartAgeCrop:35,
        holdingEndAgeCrop:100,
        graphToggle:'both',
        showContributions:true,
        showWithdrawals:true,
        showExpectedReturn:true,
        showUpperReturn:true,
        showLowerReturn:true,
        showTarget:true,
        enable_inflation:true,
        displayPotentialValue:true,
        displayCurrentSavings:true,
        displayOriginalForecast:false
      }
  }

  loadDisplaySettings(props){

	let thus = this;

	let customerRef = props.custRef;

	const apiRoute = process.env.REACT_APP_API_BASE+"/calculator/loadCalculatorDisplaySettings/"+customerRef;

		// Run the login request
    fetch(apiRoute, {
      method: 'get',
      headers: { "Content-type": "application/x-www-form-urlencoded; charset=UTF-8" },
      credentials:"include"
    })
    .then(Fetching.statusCheck)
    .then(Fetching.jsonExtract)
    .then(function (data) {
      if(data.data.length > 0){
        let planDisplayDetails = data.data[0];
        thus.setState({planDisplayDetails},()=>thus.loadCalculators(props));
      } else {
        // thus.loadCalculators(props);
      }
    })

  }

  loadAllCalculatorDisplaySettings(customerRef){

	  let thus = this;

	  const apiRoute = process.env.REACT_APP_API_BASE+"/calculator/loadAllCalculatorDisplaySettings/"+customerRef;

		  // Run the login request
	  fetch(apiRoute, {
		method: 'get',
		headers: { "Content-type": "application/x-www-form-urlencoded; charset=UTF-8" },
		credentials:"include"
	  })
	  .then(Fetching.statusCheck)
	  .then(Fetching.jsonExtract)
	  .then(function (data) {
		  let planDisplayDetails = data.data;
		 thus.setState({planDisplayDetails});


		//thus.getTokens();
	  })
  }

  loadCalculators(props, firstLoad){
	let thus = this;

	let customerRef = props.custRef;

    //stop calculator endpoint being called again
  if(typeof firstLoad == 'undefined' && !thus.state.firstLoadCompleted){
    return;
  }


	const apiRoute = process.env.REACT_APP_API_BASE+"/calculator/index/"+customerRef;

		// Run the login request
    fetch(apiRoute, {
      method: 'get',
      headers: { "Content-type": "application/x-www-form-urlencoded; charset=UTF-8" },
      credentials:"include"
    })
    .then(Fetching.statusCheck)
    .then(Fetching.jsonExtract)
    .then(function (data) {

      if (typeof data.rslt !== 'undefined' && data.rslt === 'success') {
        let calculatorSettings= data.data.calculators.settings;
        let savings = data.data.savings;
        let checkedCalculators = [];

        calculatorSettings.forEach((calculator) => {
          checkedCalculators.push(parseInt(calculator.id));
        })

        let firstLoadCompleted = true;

        thus.setState({calculatorSettings,checkedCalculators,savings,firstLoadCompleted },thus.loadAllCalculatorDisplaySettings(customerRef));

      } else {
        console.log('Request failed', data);
      }

      thus.getTokens();

    })
    .catch(function (error) {
      console.log('Request failed', error);
      thus.getTokens();
    });
  }

  getTokens () {
    fetch(process.env.REACT_APP_API_BASE + '/users/tokens', { method: 'get', credentials:"include" })
      .then(Fetching.statusCheck)
      .then(Fetching.jsonExtract)
      .then(data => this.props.dispatch({ type: 'SET_TOKENS', token: data.token, tokenKey: data.tokenKey, sessionId: data.session }))
      .catch(function (error) {
        console.log('Request failed', error);
      });
  }

  handleCheckToggle(e){
    let calculatorId = parseInt(e.target.value);
    let checkedCalculators = this.state.checkedCalculators.slice();
    let calculatorIndex = checkedCalculators.indexOf(calculatorId);
    if(calculatorIndex === -1){
      checkedCalculators.push(calculatorId);
    } else {
      checkedCalculators.splice(calculatorIndex,1)
    }
    let imageURIs = [];
    this.setState({checkedCalculators,imageURIs});
  }

  handleClear (e) {
    this.props.clearTimeMachine(e);
    this.setState({ timeEntry: "", dateEntry: "" });
  }


  toggleDropDown(e){
    e.stopPropagation();
    this.setState(prevState => ({
      dropdownVisible: !prevState.dropdownVisible,
    }));
  }

  handleDropDownBlur(e){

    if (!e.currentTarget.contains(e.relatedTarget)) {
      setTimeout( () => this.setState(prevState => ({
        dropdownVisible: false,
      })), 200);
    }
  }

  handleDownloadPDFSubMenuButtonClick(e,keepText = false){
	  this.setState({
        dropdownVisible: false,
	   });
	   if(typeof this.props.togglePDFGeneration !== 'undefined' && !keepText){
		   this.props.togglePDFGeneration();
	   }
  }

  handleGenerateProgressReviewClick(onCalculatorPage,saveCalculator,openCalculatorIndex){

    if(onCalculatorPage){
      let stateSetCallback = () => {
        setTimeout( () => this.setState({
          imagesLoading: true,
        }),1000);
      }

      this.setState({
        dropdownVisible: false,
      },() => {
        saveCalculator(openCalculatorIndex,stateSetCallback);
      });


    } else {
      this.setState({
        imagesLoading: true,
        dropdownVisible: false,

      });
    }


  }


	generateProgressReview () {
	    let data = this.state

		var thus = this;
		fetch(process.env.REACT_APP_API_BASE + '/admin/progress_reviews/generate/' + this.props.custRef+'/1',{
	      method: 'post',
	      headers: { "Content-type": "application/x-www-form-urlencoded; charset=UTF-8" },
	      credentials:"include",
	      body:'data=' + JSON.stringify(data)
	    })
	    .then(Fetching.statusCheck)
	    .then(Fetching.jsonExtract)
	    .then(function (data) {
	      console.log('Request succeeded with JSON response', data);

	      if (typeof data.rslt == 'undefined' || data.rslt !== 'success') {
	        // Set the updated state
	        alert('Sorry but there was an error generating the Progress Review. Please try again.');
	      } else {
	        thus.setState({
	          imagesLoading:false,
	          imageURIs:[],
	          dropdownVisible:false,
	          imagesLoaded:[]
	        })

	        if(typeof thus.props.updateFiles !== 'undefined' ){
	          thus.props.updateFiles();
			   window.open(process.env.REACT_APP_API_BASE+"/admin/files/download/"+data.file,'_blank');
	        } else {
	          window.open(process.env.REACT_APP_API_BASE+"/admin/files/download/"+data.file,'_blank');
	        }

	      }

	    })
	    .catch(function (error) {
	      console.log('Request failed', error);
	      thus.setState({
	        imagesLoading:false,
	        imageURIs:[],
	        imagesLoaded:[],
	        dropdownVisible:false
	      })
			alert('Sorry but there was an error generating and saving the Progress Review. Please try again.');
	    });
	}

  getTargetValue(calculatorData){
    let target = 0;

    if( calculatorData.is_specific_amount === 1){
      target = calculatorData.target
    } else {
      target = (calculatorData.withdrawal_rate > 0)  ? (calculatorData.target  ) / (calculatorData.withdrawal_rate / 100) : calculatorData.target ;
    }

    return target;
  }

  getIndicesToRemove(props,data){

    let indicesToSplice = [];

    let titleRow = data[0];
    for(let i = 0; i< titleRow.length;i++){
      if(!props.showWithdrawals && titleRow[i] === 'Withdrawals'){
        indicesToSplice.push(i);
      }

      if(!props.showContributions && titleRow[i] === 'Contributions'){
        indicesToSplice.push(i);
      }

      if(!props.displayOriginalForecast && titleRow[i] === 'Original'){
        indicesToSplice.push(i);
      }

      if(!props.showUpperReturn && titleRow[i] === 'Upper'){
        indicesToSplice.push(i);
      }

      if(!props.showExpectedReturn && titleRow[i] === 'Expected Return'){
        indicesToSplice.push(i);
      }

      if(!props.showLowerReturn && titleRow[i] === 'Lower'){
        indicesToSplice.push(i);
      }

      if(!props.showTarget && titleRow[i] === 'Target'){
        indicesToSplice.push(i);
      }
    }

    return indicesToSplice;
  }

  removeIndicesFromDataSet(indicesToRemove,data){

    for(var i =0; i<data.length;i++){
      let newDataEntry = data[i];
      for(var j = indicesToRemove.length -1 ; j >=0 ; j--){
        newDataEntry.splice(indicesToRemove[j],1);

      }
      data[i] = newDataEntry;
    }

    return data;
  }

  updateSeriesConfig(indicesToRemove,props,data,seriesOptions){

    for(var i = indicesToRemove.length -1 ; i >=0 ; i--){
      delete seriesOptions[indicesToRemove[i] - 1];
    }

    let newSeriesOptions = {};
    let counter = 0;
    for(var key in seriesOptions){
      newSeriesOptions[counter] = seriesOptions[key];
      counter++;
    }

    return newSeriesOptions;
  }



  cropDataByAge(data,startAge,endAge){
    let startRow = 0
    for(let a = data.length - 1; a > startRow ;a--){

      if(data[a][0] < startAge || data[a][0] > endAge){
        data.splice(a,1);
      }

    }

    return data;
  }

  //doesColumnHaveNullsOnly - Array of length column count with all values initialised to true
  //data - our created dataset
  checkForColumnsWithEntireNullValues(doesColumnHaveNullsOnly,data){
    //We assume every column is set to null throughout
    for(let i = 1; i < data.length;i++){
      for(let j=0;j<data[i].length;j++){
        //So for every row check that it's non-null
        if(data[i][j] !== null){
          //Found a non-null value so set to false
          doesColumnHaveNullsOnly[j] = false;
        }
      }
    }

    return doesColumnHaveNullsOnly;
  }

  addColumnIndicesWithNullValues(indicesToRemove,doesColumnHaveNullsOnly){
    for(var i = 0 ; i< doesColumnHaveNullsOnly.length;i++){
      if(doesColumnHaveNullsOnly[i]){
        indicesToRemove.push(i);
      }
    }

    return indicesToRemove;
  }

  removeNullColumns(data,doesColumnHaveNullsOnly){
    for(var i = doesColumnHaveNullsOnly.length-1 ; i >= 0 ; i--){
      if(doesColumnHaveNullsOnly[i]){
        for(var j = 0 ; j< data.length;j++){
          data[j].splice(i,1);
        }
      }
    }

    return data;
  }

  toggleShowPercentageReturn(){
    this.setState((state ) => ({
      showPercentageReturn: !state.showPercentageReturn
    }))
  }

  toggleShowInvestmentBreakdown(){
    this.setState((state ) => ({
      showInvestmentBreakdown: !state.showInvestmentBreakdown
    }))
  }

  render() {


    let thus = this;
    let { imagesLoading,showPercentageReturn,showInvestmentBreakdown} = this.state;

    let imageCreators = null

    if(imagesLoading){

      if(this.state.checkedCalculators.length === 0){
        //generate progress review if no graphs selected
        thus.generateProgressReview();

      } else {
        //render graphs to get image URIs
        imageCreators =  this.state.calculatorSettings.map((calculator,i) => {

		 let id = calculator.id;
		 let planDisplayDetails = this.state.planDisplayDetails[id];

		 if(typeof planDisplayDetails === 'undefined'){
			 planDisplayDetails = this.getDefaultDisplaySettings();
		 }

		  const presentAge = parseInt(calculator.age);

	      let initialSavingsAge =presentAge

	      if(typeof Object.keys(this.state.savings)[0] !== 'undefined'){
	        initialSavingsAge =  parseInt(Object.keys(this.state.savings)[0]);
	      }

          let target = this.getTargetValue(calculator);
          let data = [];
          let indicesToRemove = [];
          let dataSet = [];
          let seriesOptions = {};

          const pdfDataOptions = {
            hideOriginalForecast:true,
            enableInflation:true
          }

          if(planDisplayDetails.displayOriginalForecast){
            pdfDataOptions.hideOriginalForecast = false
          }

          if(!planDisplayDetails.enable_inflation){
            pdfDataOptions.enableInflation = false
          }

          let startAgeCrop = planDisplayDetails.startAgeCrop;
          let endAgeCrop = planDisplayDetails.endAgeCrop;

          data.push(['Age', 'Current Value','Contributions', 'Withdrawals','Original', 'Lower', 'Expected Return','Upper','Target']);
          indicesToRemove = this.getIndicesToRemove(planDisplayDetails ,data);

          data = this.removeIndicesFromDataSet(indicesToRemove,data);

          dataSet = createDataEntries(DISPLAY_BOTH,this.state.savings,initialSavingsAge,calculator.retirement_age,calculator.age,calculator,target,pdfDataOptions,indicesToRemove);

          data = data.concat(dataSet);

          data = this.cropDataByAge(data,startAgeCrop,endAgeCrop);

          let doesColumnHaveNullsOnly = new Array(data[0].length).fill(true);

          doesColumnHaveNullsOnly = this.checkForColumnsWithEntireNullValues(doesColumnHaveNullsOnly,data);
          indicesToRemove = this.addColumnIndicesWithNullValues(indicesToRemove,doesColumnHaveNullsOnly);
          data = this.removeNullColumns(data,doesColumnHaveNullsOnly);

          seriesOptions = {...CONFIGURATION.series.both};
          seriesOptions = this.updateSeriesConfig(indicesToRemove,this.state,data,seriesOptions);

          if(this.state.checkedCalculators.indexOf(calculator.id) !== -1){

            const options={
              vAxis: {
                title: 'Investment Value',
                gridlines: {
                  color: 'transparent'
                }
              },
              hAxis: {
                title: 'Age',
                baseline: 1,
                baselineColor: 'black',
                viewWindow: {
                  min: startAgeCrop,
                  max: endAgeCrop
                },
                gridlines: {
                  color: 'transparent'
                },
              },
              seriesType: 'bars',
              interpolateNulls :true,
              legend: { position: 'bottom',textStyle: {fontSize:12}},
              chartArea: {'width': '80%', 'height': '70%'},
              width:'100%',
              series:seriesOptions,
            };

            return (
              <Chart
              key={"calculator_"+i}
              width={'1000px'}
              height={'500px'}
              chartType="ComboChart"
              loader={<div>Loading Chart</div>}
              chartEvents={[{
                eventName: "ready",
                callback({ chartWrapper }) {
                  const chart = chartWrapper.getChart();
                  let imageURIs = thus.state.imageURIs.slice();
                  let imagesLoaded = thus.state.imagesLoaded;
                  imageURIs.push(chart.getImageURI());

                  if(imagesLoaded.indexOf(calculator.id) === -1){
                    imagesLoaded.push(calculator.id);
                    thus.setState({imageURIs,imagesLoaded},() => {
                      if(thus.state.imagesLoaded.length === thus.state.checkedCalculators.length){
                        //submit progress review if all images are loaded
                        thus.generateProgressReview();
                      }
                    });
                  }


                }
              }]}
              data={data}
              options={options}
              rootProps={{ 'data-testid': '1' }}
            />)
          } else {
            return null;
          }

        });
      }


    }

	var progRevBtn = "";

	if (typeof this.props.hideProgRev == 'undefined' || !this.props.hideProgRev) {

    let dropDownContent = <li>No Prediction Graphs Found</li>;

    if(this.state.calculatorSettings.length > 0){

    	dropDownContent = this.state.calculatorSettings.map((calculator,i) => {
        	let isChecked = this.state.checkedCalculators.indexOf(calculator.id) !== -1
        	return <li key={"calculator-"+i}><input name={"calculator-"+i} id={"calculator-"+i} onChange={(e) => this.handleCheckToggle(e)} value={calculator.id} checked={isChecked} type="checkbox"/><label htmlFor={"calculator-"+i} style={ styles.padLft }>{calculator.title}</label></li>
    	})
    }

    let dropDown = null;

    if(this.state.dropdownVisible ){
        dropDown = (
          <Dropdown
            dropDownContent={dropDownContent}
            handleGenerateProgressReviewClick={(e) => this.handleGenerateProgressReviewClick(this.props.onCalculatorPage,this.props.saveCalculator,this.props.openCalculator)}
            handleDropDownBlur={this.handleDropDownBlur}
            custRef={this.props.custRef}
            handleDownloadPDFSubMenuButtonClick={this.handleDownloadPDFSubMenuButtonClick}
            dateEntry={this.props.dateEntry}
            timeEntry={this.props.timeEntry}
            updateFiles={ this.props.updateFiles }
            toggleShowPercentageReturn={this.toggleShowPercentageReturn}
            showPercentageReturn={showPercentageReturn}
            toggleShowInvestmentBreakdown={this.toggleShowInvestmentBreakdown}
            showInvestmentBreakdown={showInvestmentBreakdown}
           />
         );
      }

      let toggleDropdownEvent = !this.state.dropdownVisible ?  (e) => this.toggleDropDown(e): (e) => console.log('test');

	  let pdfGenerating = true;

	  if(this.props.pdfGenerating !== 'undefined'){
		  pdfGenerating = this.props.pdfGenerating;
	  }

    progRevBtn = (
        <div >
	        <button
	          style={{ ...styles.round, ...styles.download,
	          opacity:imagesLoading || pdfGenerating ? 0.5: 1  }}
	          onClick={toggleDropdownEvent }
	          disabled={imagesLoading ? true: false }
              className='responsive-btn responsive-btn-download'
	          >
	          {!imagesLoading && !pdfGenerating ? "PDF Export": 'Generating...'}
	        </button>
	        {dropDown}
        </div>
	)

    return (
      <div style={styles.container}>
        { progRevBtn }
        <div style={{position:"absolute",right:"-9999px",top:"-9999px"}}>
        {imageCreators}
        </div>
      </div>
    );

    }
  }

}

var styles = {
  container:{
    position:"relative",
    overflowY:"visible",
    display:'inline-block',
    zIndex:'999',
    paddingTop:'0'
  },
  form: {
    float: "right",
    marginRight: "1%",
    marginTop: "-5px",
    position:"relative"
  },
  label: {
    width: 85,
    display: "inline-block"
  },
  inputWrap: {
    display: "inline-block",
    width: 145
  },
  input: {
    display: 'inline-block',
    padding: "5px 12px",
    border: 0,
    boxShadow: '1px 1px 5px rgba(0,0,0,0.15)',
    fontSize: '14px',
    fontFamily: "'Mukta', sans-serif",
    width: 155,
    marginLeft: 5
  },
  time: {
    width: 70
  },
  wrapper: {
    display: 'inline-block',
    marginTop: 0
  },
  round: {
    borderRadius: 25,
    display: 'inline-block',
    margin: "0 0 0 18px",
    padding: "5px 12px",
    fontSize: 13,
		border: 'none'
  },
  red: {
    background: Colours.red,
    margin: "0 0 0 5px"
  },
  download: {
    marginLeft: 45,
    background: Colours.txtGrey + " url('" + Download + "') left 16px center / 12px auto no-repeat",
    color: Colours.txtWhite,
    textDecoration: 'none',
	paddingRight: 15,
    paddingLeft: 38,
	cursor: 'pointer'
  },
	progressReview: {
    marginLeft: 10,
    background: Colours.txtGrey + " url('" + ProgressReviewIcon + "') left 16px center / 12px auto no-repeat",
    color: Colours.txtWhite,
    textDecoration: 'none',
    paddingRight: 15,
    paddingLeft: 38,
		cursor: 'pointer'
  },
  padLft: {
    paddingLeft: 5
  }
};

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(ProgressReview);
